import { createAnimation } from 'https://cdn.jsdelivr.net/npm/@ionic/core@latest/dist/esm/index.mjs';

// Funktion zum Einblenden einer Komponente von links
export const fadeInToLeft = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(1250)
        .iterations(1)
        .fromTo('transform', 'translateX(-50px)', 'translateX(0)')
        .fromTo('opacity', '0', '1');
    
    return animation;
}
// Funktion zum Ausblenden einer Komponente nach rechts
export const fadeOutToRight = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(750)
        .iterations(1)
        .fromTo('transform', 'translateX(0)', 'translateX(50px)')
        .fromTo('opacity', '1', '0');
    
    return animation;
}
// Funktion zum Einblenden einer Komponente von rechts
export const fadeInToRight = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(1250)
        .iterations(1)
        .fromTo('transform', 'translateX(50px)', 'translateX(0)')
        .fromTo('opacity', '0', '1');
    
    return animation;
}
// Funktion zum Ausblenden einer Komponente nach links
export const fadeOutToLeft = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(750)
        .iterations(1)
        .fromTo('transform', 'translateX(0)', 'translateX(-50px)')
        .fromTo('opacity', '1', '0');
    
    return animation;
}

export const addingBlue = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(750)
        .iterations(1)
        .keyframes([
            { offset: 0, fontSize: '18px', color: '#318CE7' },   // Anfang
            { offset: 0.5, fontSize: '20px', color: '#318CE7' }, // Mitte
            { offset: 1, fontSize: '18px', color: 'white' }        // Ende
        ]);

    
    return animation;
}

export const substractRed = (elementRef) => {
    const animation = createAnimation()
        .addElement(elementRef.current)
        .duration(750)
        .iterations(1)
        .keyframes([
            { offset: 0, fontSize: '18px', color: 'red' },
            { offset: 0.5, fontSize: '16px', color: 'red' },
            { offset: 1, fontSize: '18px', color: 'white' }
        ]);


    
    return animation;
}

