 import { IonRow } from '@ionic/react';
 
 import "./header.css"
import { Link } from 'react-router-dom';
 export default function Header() {
    return (
        <IonRow className = "header-style">
                <Link to="/" className="nav-link">
                    Homepage
                </Link>
                <Link to="/photos" className="nav-link">
                    Fotos
                </Link>
                <Link to="/" className="nav-link">
                    Dresscode
                </Link>
        </IonRow>
    );
 }
