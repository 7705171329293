import { IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";
import { Link } from 'react-router-dom';
import WeddingLogo from "../../../assets/Logo/WeddingLogoNoBg.png";
import Verlobung1 from "../../../assets/OurPics/Verlobung1.jpeg";
import Verlobung2 from "../../../assets/OurPics/Verlobung2.jpeg";

import "./weddingsection.css";
import MapComponent from "./LeafFletMap";
import React, { useEffect, useRef } from "react";
import { fadeInToLeft, fadeInToRight } from '../utilities/animation';

import QuiltedImageList from "./QuiltedImageList";

const DividerWithDot = () => {
    return (
      <div style = {{width: "100%", display:"flex", justifyContent:"center"}}>
      <div style={{ display: 'flex', alignItems: 'center', width: '99%'}}>
        <div style={{ flex: 1, height: '1px', backgroundColor: '#000' }}></div>
        <div style={{ margin: '0 8px', height: '6px', width: '6px', backgroundColor: '#000', borderRadius: '50%' }}></div>
        <div style={{ flex: 1, height: '1px', backgroundColor: '#000' }}></div>
      </div>
      </div>
    );
  };
  
  export default function WeddingSection(){
      const [rightRef, leftRef] = [useRef(null), useRef(null)];
      const [isVisible, setIsVisible] = React.useState(false);
      const [isHover, setIsHover] = React.useState(false);

      useEffect(() => {
        const fadeInRight = fadeInToRight(leftRef);
        const fadeInLeft = fadeInToLeft(rightRef);

            if (isVisible) {
              fadeInRight.play();
              fadeInLeft.play();
            } 
            // else {
            //   fadeOutLeft.play();
            //     fadeOutRight.play();
            // }
        }, [isVisible, rightRef, leftRef]);

        useEffect(() => {
      
          const observer = new IntersectionObserver(
              (entries) => {
                  entries.forEach(entry => {
                      if (entry.target === rightRef.current && entry.isIntersecting) {
                          setIsVisible(1);
                      }
                      if (entry.target === leftRef.current && !entry.isIntersecting) {
                          setIsVisible(0);
                      }
                  });
              },
              {
                  threshold: 0.5
              }
          );
      
          const rRef = rightRef.current;
          const lRef = leftRef.current;
      
          if (rRef) observer.observe(rRef);
          if (lRef) observer.observe(lRef);
      
          return () => {
              if (rRef) observer.unobserve(rRef);
              if (lRef) observer.unobserve(lRef);
          };
      },[leftRef, rightRef]);

    return (
        <IonRow className = "wedding-section">
            <IonCol ref = {rightRef} sizeLg = "9" sizeXs = "12" className = "col-1-textcontainer">
                <IonRow style = {{display:"flex", flexDirection:"column"}}>
                    <IonImg src={WeddingLogo} className = "wedding-logo" alt="Hochzeitslogo" />
                    <h1>The Wedding</h1>
                </IonRow>
                <DividerWithDot />
                    <IonRow style = {{display: "flex", flexDirection: "column"}}>
                      <IonGrid className ="ionGrid-p-InfosWedding">
                        <p className = "p-place-kesselhaus">Kesselhaus Kolbermoor</p>
                        <p>An d. Alten Spinnerei 5</p>
                        <p>83059 Kolbermoor</p>
                        <Link className = "link-page-kesselhaus" to = "https://www.kesselhaus-kolbermoor.de/"><p>Kesselhaus-kolbermoor.de</p></Link>
                      </IonGrid>
                      <IonGrid className ="ionGrid-p-InfosWedding">
                        <p>Samstag, 20. September 2025</p>
                        <p style = {{color: "res"}}>Beginn: 14:00 Uhr</p>
                      </IonGrid>
                    </IonRow>
                    <IonRow style = {{width: "100%", justifyContent:"center"}}>
                    <IonCol sizeXs = "12" sizeMd = "6" sizeLg = "6" style = {{display:"flex", width: "100%", justifyContent:"center"}}>
                          <QuiltedImageList />
                        </IonCol>
                        <IonCol sizeXs = "12" sizeMd = "6" sizeLg = "6" style = {{display:"flex", width: "100%", justifyContent:"center"}}>
                          <MapComponent />
                        </IonCol>

                    </IonRow>
            </IonCol>
            <IonCol sizeLg = "3" sizeXs = "12" className = "col-2-imagecontainer">
                <IonImg onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}  style = {{height: "100%"}} ref = {leftRef} src = {isHover ? Verlobung1 : Verlobung2} className = "col-2-verlobung-img" alt="Verlobungsbild"/>
            </IonCol>
        </IonRow>
    );
}