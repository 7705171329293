import React from 'react';
import './danksagung.css';
import { IonRow, IonCol } from '@ionic/react';

export default function Danksagung(){
    return (
        <IonRow className="danksagung-box">
            <IonCol className="col-text-danksagung" size="12" >
                <h1 className="danksagung-title">Danksagung</h1>
                <br />
                <p>Wir möchten uns bei allen bedanken, die uns bei der Organisation und Planung unserer Hochzeit unterstützt haben.</p>
                <br />
                <p>Ein besonderer Dank geht an unsere Familien und Freunde, die uns in dieser aufregenden Zeit begleitet haben.</p>
            </IonCol>
        </IonRow>
    );
}