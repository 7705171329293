import React from 'react';
import TardisDusk from "../../../assets/Tardis/mainTardis.jpeg";
import Farbpalette from "../../../assets/Farbpalette/FarbpaletteNew.png";
import {  IonCol, IonRow, IonImg } from '@ionic/react';

import './dresscodesection.css';


import { fadeInToLeft, fadeInToRight, fadeOutToLeft, fadeOutToRight } from '../utilities/animation';
import { useEffect, useRef } from 'react';


function openHelp() {
    alert("Dresscode: Dusk\n\nThe dresscode for our wedding is Dusk. This means that we would like you to wear something in the colors of the sunset. This can be anything from a light pink to a dark purple. We are looking forward to seeing all the beautiful outfits!");
}

export default function DresscodeSection() {
    const [rightRef, leftRef] = [useRef(null), useRef(null)];
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
        const fadeInRight = fadeInToRight(leftRef);
        const fadeInLeft = fadeInToLeft(rightRef);
        const fadeOutLeft = fadeOutToLeft(rightRef);
        const fadeOutRight = fadeOutToRight(leftRef);

            if (isVisible) {
              fadeInRight.play();
              fadeInLeft.play();
            } 
            else {
              fadeOutLeft.play();
                fadeOutRight.play();
            }
        }, [isVisible, rightRef, leftRef]);

        useEffect(() => {
      
          const observer = new IntersectionObserver(
              (entries) => {
                  entries.forEach(entry => {
                      if (entry.target === rightRef.current && entry.isIntersecting) {
                          setIsVisible(1);
                      }
                      if (entry.target === leftRef.current && !entry.isIntersecting) {
                          setIsVisible(0);
                      }
                  });
              },
              {
                  threshold: 0.5
              }
          );
      
          const rRef = rightRef.current;
          const lRef = leftRef.current;
      
          if (rRef) observer.observe(rRef);
          if (lRef) observer.observe(lRef);
      
          return () => {
              if (rRef) observer.unobserve(rRef);
              if (lRef) observer.unobserve(lRef);
          };
      }, [rightRef, leftRef]);


    return (
        <IonRow className="dresscode-section">
                <IonCol className = "col-1" ref = {rightRef} sizeLg="3" sizeXs = "12">                
                    <p className = "p-title-dresscode">Dresscode</p>
                    <p  className = "p-farbpalette">Farbpalette</p>
                    <IonRow style = {{width: "100%", justifyContent: "center"}}>
                        <IonImg className="img-farbpallette" src={Farbpalette} alt="Farbpalette" />
                    </IonRow>
                </IonCol>
                <IonCol className = "col-2"  ref = {leftRef} sizeLg="9" sizeXs = "12">
                    <IonImg className="frame-img" src={TardisDusk} alt="Tardis" onClick={openHelp} />
                </IonCol>
        </IonRow>
    );
}
