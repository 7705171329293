// Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
 
import Header from './components/Header';
function Layout() {
    return (
        <div >
            <Header />

            <main>
                <Outlet />  {/* Hier wird die index Route (Homepage) gerendert */}
            </main>
            <footer>

            </footer>
        </div>
    );
}

export default Layout;
