import * as React from 'react';
import { IonCol } from '@ionic/react';
import KesselHausImpression1 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_1.png";
import KesselHausImpression2 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_2.png";
import KesselHausImpression3 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_3.png";
import KesselHausImpression4 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_4.png";
import KesselHausImpression5 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_5.png";
import KesselHausImpression6 from "../../../assets/Kesselhaus/Kesselhaus_Website_Impressionen_6.png";
import KesselHausImpressionLocationK1 from "../../../assets/Kesselhaus/Kesselhaus_Website_Location_K_1.png";
import KesselHausImpressionLocationR1 from "../../../assets/Kesselhaus/Kesselhaus_Website_Location_R_1.png";
import KesselHausImpressionLocationR3 from "../../../assets/Kesselhaus/Kesselhaus_Website_Location_R_3.png";
import KesselHausImpression7 from "../../../assets/Kesselhaus/Kesselhaus_Wedding1.png";
import KesselHausImpression8 from "../../../assets/Kesselhaus/Kesselhaus_Wedding2.png";
import KesselHausImpression9 from "../../../assets/Kesselhaus/Kesselhaus_Wedding3.png";
import Modal from '@mui/material/Modal';


export default function QuiltedImageList() {
  const [openModal, setOpenModal] = React.useState(false);
  const [focusImg, setFocusImg] = React.useState("");

function randomWidth(index){
  const nmb = index % 4;
  switch (nmb) {
    case 0:
      return "90%";
    case 1:
      return "50%";
    case 2:
      return "33.33%";
    case 3:
      return "66.66%";
    default:
      return "90%";
  }
};

  return (
<>
<IonCol className = "col-img-list">
  {itemData.map((item, index) => (
    <img key = {index} src={item.img} alt={item.title} 
    style={{
      objectFit:"cover", 
      width: randomWidth(index),
    }} onClick={() => {setFocusImg(item.img); setOpenModal(true)}}/>
  ))}
</IonCol>
<Modal
  open={openModal}
  onClose={() => setOpenModal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  style = {{display:"flex", justifyContent:"center", alignItems:"center", }}
>
  <img src={focusImg} alt="focused" style={{display:"flex", justifyContent:"center", alignItems:"center", height: "80%"}}/>
</Modal>

</>
  );
}

const itemData = [
  {
    img: KesselHausImpression8,
    title: 'Kesselhaus Location Rosengarten 3. Bild',
    rows: 2,
    cols: 3,
  },
  {
    img: KesselHausImpression9,
    title: 'Kesselhaus Location Rosengarten 3. Bild',
    rows: 3,
    cols: 2,
  },
  
  {
    img: KesselHausImpression7,
    title: 'Kesselhaus Location Rosengarten 3. Bild',
    rows: 2,
    cols: 2,
  },
  {
    img: KesselHausImpression1,
    title: 'Kesselhaus Impression 1. Bild',
    rows: 3,
    cols: 3,
  },
  {
    img: KesselHausImpression2,
    title: 'Kesselhaus Impression 2. Bild',
  },
  {
    img: KesselHausImpression3,
    title: 'Kesselhaus Impression 3. Bild',
  },
  {
    img: KesselHausImpression4,
    title: 'Kesselhaus Impression 4. Bild',
    cols: 2,
  },
  {
    img: KesselHausImpression5,
    title: 'Kesselhaus Impression 5. Bild',
    cols: 2,
  },
  {
    img: KesselHausImpression6,
    title: 'Kesselhaus Impression 6. Bild',
    rows: 2,
    cols: 2,
  },
  {
    img: KesselHausImpressionLocationK1,
    title: 'Kesselhaus Location Kesselhaus 1. Bild',
  },
  {
    img: KesselHausImpressionLocationR1,
    title: 'Kesselhaus Location Rosengarten 1. Bild',
  },
  {
    img: KesselHausImpressionLocationR3,
    title: 'Kesselhaus Location Rosengarten 3. Bild',
    rows: 2,
    cols: 2,
  },

];
