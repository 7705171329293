import { IonCol, IonPage, IonRow } from '@ionic/react';
import { useState, useEffect, useRef } from 'react';
import './css/fonts.css';
import "./css/homepage.css";
import WeddingSection from './components/WeddingSection';
import DresscodeSection from './components/DresscodeSection';
import PhotoSection from './components/PhotoSection';
import Danksagung from './components/Danksagung';
// import TimeTable from './components/TimeTable';
// import OurHistory from './components/OurHistory';
// import Hotels from './components/Hotels';
// import LandingSection from './components/LandingSection';


export default function Homepage() {
  const [dateCounter, setDateCounter] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const finalDate = new Date("2025-09-20");

  // Referenzen für die Counter-Elemente
  const daysRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  useEffect(() => {
    const updateCounter = () => {
      const now = new Date();
      const differenceInMilliseconds = finalDate - now;

      const counterDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      const counterHours = Math.floor((differenceInMilliseconds / (1000 * 60 * 60)) % 24);
      const counterMinutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
      const counterSeconds = Math.floor((differenceInMilliseconds / 1000) % 60);

      setDateCounter(prevCounter => {
        // Nur aktualisieren, wenn sich der Wert ändert
        if (prevCounter.days !== counterDays) {
          updateCounterValue(daysRef.current, counterDays);
        }
        if (prevCounter.hours !== counterHours) {
          updateCounterValue(hoursRef.current, counterHours);
        }
        if (prevCounter.minutes !== counterMinutes) {
          updateCounterValue(minutesRef.current, counterMinutes);
        }
        if (prevCounter.seconds !== counterSeconds) {
          updateCounterValue(secondsRef.current, counterSeconds);
        }

        return {
          days: counterDays,
          hours: counterHours,
          minutes: counterMinutes,
          seconds: counterSeconds
        };
      });
    };

    const intervalId = setInterval(updateCounter, 1000);

    return () => clearInterval(intervalId); // Cleanup bei Entmontierung
  });

  function updateCounterValue(element, newValue) {
    if (element) {
      element.style.animation = 'slideOut 0.5s forwards'; // Slide out
      setTimeout(() => {
        element.textContent = newValue; // Wert aktualisieren
        element.style.animation = 'slideIn 0.5s forwards'; // Slide in
      }, 500); // Warte auf das Ende der slideOut-Animation
    }
  }

  return (
    <IonPage className="homepage-style" style = {{overflow: "hidden"}}>
        <div className="img-scrollbackground">
        <p className="p-title">Who gets married?</p>
        <p className="p-counter">
          <span className="countdown-title-label">Countdown</span>
          <IonRow>
            <IonCol><span ref={daysRef} className="days"> {dateCounter.days}</span></IonCol>
            <IonCol style = {{padding: 0, margin:0, maxWidth: "fit-content" }}><span>:</span></IonCol>
            <IonCol><span ref={hoursRef} className="hours"> {dateCounter.hours}</span></IonCol>
            <IonCol style = {{padding: 0, margin:0, maxWidth: "fit-content" }}><span>:</span></IonCol>
            <IonCol><span ref={minutesRef} className="minutes"> {dateCounter.minutes}</span></IonCol>
            {/* <IonCol style = {{padding: 0, margin:0, maxWidth: "fit-content" }}><span>:</span></IonCol> */}
            {/* <IonCol><span ref={secondsRef} className="seconds"> {dateCounter.seconds}</span></IonCol> */}
          </IonRow>
          <IonRow>
            <IonCol><span className = "countdown-label"> Tage</span></IonCol>
            <IonCol><span className = "countdown-label"> Stunden</span></IonCol>
            <IonCol><span className = "countdown-label"> Minuten</span></IonCol>
            {/* <IonCol><span className = "countdown-label"> Sekunden</span></IonCol> */}
          </IonRow>
        </p>
      </div>

      <IonRow style={{ borderTop: "5px solid #4a0000" }}>
        <WeddingSection />
      </IonRow>
      <IonRow>
        <DresscodeSection />
      </IonRow>
      <IonRow>
        <PhotoSection />
      </IonRow>
      {/* <IonRow>
        <TimeTable />
      </IonRow>
      <IonRow>
        <OurHistory />
      </IonRow>
      <IonRow>
        <Hotels />
      </IonRow> */}
      <Danksagung />
    </IonPage>
  );
}
