import React, { useState } from 'react';
import { IonButton, IonRow, IonGrid } from "@ionic/react";


import './photosection.css';

export default function PhotoSection() {
    const [images, setImages] = useState([]);

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
            uploadedImages.push(URL.createObjectURL(files[i]));
        }
        setImages([...images, ...uploadedImages]);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        handleFiles(files);
    };

    return (
        <IonRow className="section-photo">
            <IonGrid className="section-photo-card">
                <h1>Our Photos</h1>
                <IonRow>
                    <IonButton>Lade Fotos hoch</IonButton>
                    <IonButton>Fotos Fotografin</IonButton>
                </IonRow>
                {/* Drag and Drop Area */}
                <div
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={handleDrop}
                    style={{
                        border: '2px dashed #ccc',
                        borderRadius: '10px',
                        padding: '20px',
                        textAlign: 'center',
                        margin: '20px 0',
                        backgroundColor: '#f9f9f9',
                        cursor: 'pointer',
                    }}
                >
                    <p>Ziehe deine Bilder hierher oder klicke, um sie auszuwählen</p>
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="fileUpload"
                    />
                    <label htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                        <IonButton>Wähle Bilder aus</IonButton>
                    </label>
                </div>

                {/* Display Uploaded Images */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                    {images.map((image, index) => (
                        <img key={index} src={image} alt={`upload-${index}`} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                    ))}
                </div>
                <IonRow className="photo-collection">

                </IonRow>
                <IonButton>View More</IonButton>

            </IonGrid>
        </IonRow>
    );
}
